import { Outlet } from "react-router-dom";

const Root = ({ props }) => {
	return (
		<div className="min-h-screen overflow-x-hidden py-8 px-8 md:px-24 transition-all duration-100 flex flex-col text-off-black w-full">
			<div className="mb-8 flex-1">
				<Outlet />
			</div>
			<div className="w-full border-t border-grey-600 pt-4">
				<div className="flex space-x-8 items-center justify-end">
					<a
						className="rounded-md px-4 py-2 bg-primary text-white font-semibold cursor-pointer w-24 text-center transition-all duration-100 hover:bg-primary-600"
						href={"mailto:dominic.cischke@gmail.com"}
					>
						Contact
					</a>
					<p className="text-center md:text-right">
						Dominic Cischke, {new Date().getFullYear()}
					</p>
				</div>
			</div>
		</div>
	);
};

export default Root;
