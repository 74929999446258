import { useEffect } from "react";
import PrimaryLogo from "assets/primary-logo.svg";
import timeline from "data/timeline";
import writing from "data/writing";

const Event = ({ text }) => <p className="text-sm font-light">{text}</p>;

const TimelineBlock = ({ index, year, events }) => (
	<div className="text-right">
		<p
			className={
				index === 0 ? "text-lg font-semibold text-primary" : "text text-medium"
			}
		>
			{year}
		</p>
		<div className="">
			{events.map((event, key) => (
				<Event key={`${key}-event`} text={event} />
			))}
		</div>
	</div>
);

const WritingBlock = ({ title, description, file_name }) => (
	<div>
		<a
			className="font-semibold text-xl cursor-pointer transition-all duration-200 hover:text-primary w-fit"
			href={`/${file_name}`}
			target="_blank"
			rel="noreferrer"
		>
			{title}
		</a>
		<p className="w-full md:w-1/2">{description}</p>
	</div>
);

const Home = (props) => {
	useEffect(() => {
		document.title = "Dominic Cischke";
	}, []);

	return (
		<div className="">
			<div className="flex items-center space-x-4">
				<img src={PrimaryLogo} className="h-20 w-20" alt="dc" />
				<div className="font-semibold flex flex-col justify-between">
					<p className="text-4xl text-primary">DOMINIC</p>
					<p className="text-2xl">CISCHKE</p>
				</div>
			</div>

			<div className="mt-6">
				<div className="col-span-5">
					<div className="w-full md:w-1/2">
						Hi, welcome to my website! I'm an expert generalist with a draw towards 
						early-stage startup operations and finance. I have a history of work 
						across industries, from Web3 FinTech to HealthTech. I enjoy taking an 
						innovative approach to unique problems and digging deep to solve them. 
						Feel free to check out some of my work shown below!
					</div>
					<div className="mt-6">
						<div className="relative w-fit mb-4">
							<p className="text-3xl font-bold">Writing</p>
							<div className="absolute bottom-0 h-[2px] w-2/3 bg-primary"></div>
						</div>
						<div className="space-y-2">
							{writing.map((block, key) => (
								<WritingBlock key={`${key}-writing`} {...block} />
							))}
						</div>
					</div>
				</div>
				<div className="col-start-8 space-y-2">
					{/*timeline.map((props, key) => (
						<TimelineBlock key={`${key}-timeline`} index={key} {...props} />
					))*/}
				</div>
			</div>
		</div>
	);
};

export default Home;
