import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";

import Root from "screens/Root";
import Home from "screens/Home";

const App = (props) => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Root />}>
					<Route path="*" element={<Navigate to="/" replace />} />

					<Route path="/" element={<Home />} />
					{/* <Route path="forge" element={<Forge />} /> */}
					{/* <Route path="writing" element={<Writing />} /> */}
					{/* <Route path="etc" element={<Lambda />} /> */}
				</Route>
			</Routes>
		</Router>
	);
};

export default App;
