const writing = [
	{
		title: "Blockchain Technology for Business",
		description:
			"Authored a book to be used in the instruction of an MBA course on the business applications of blockchain technology.",
		file_name: "blockchain-technology-for-business.pdf",
	},
	{
		title: "Lever Finance",
		description:
			"Ideated and architected a decentralized autonomous protocol for the loan-based purchasing of blockchain assets.",
		file_name: "lever-finance-whitepaper.pdf",
	},
	{
		title: "Economics Honors Thesis",
		description:
			"Investigating ERC-20 Cryptocurrency Market Efficiency: Investigating High Frequency Efficiency Across Systemic Events - The Ethereum Merge and The Collapse of FTX.",
		file_name: "efficient-markets-thesis.pdf",
	},
];

export default writing;
